






















import Vue from 'vue'
import {Preferences} from '@/types'
import {Component} from 'vue-property-decorator'
import {mapActions, mapGetters} from 'vuex'

@Component({
  components: {},
  computed: {
    ...mapGetters({
      preferences: 'allPreferences',
    }),
  },
  methods: {
    ...mapActions({
      updatePreferencesRequest: 'updatePreferencesRequest',
    }),
  },
})
export default class SpeedSettings extends Vue {
  preferences!: Preferences

  updatePreferencesRequest!: (_: any) => void

  changeSettings(property: string, value: string | boolean | number) {
    this.updatePreferencesRequest({[property]: value})
  }
}
