




























































import Vue from 'vue'
import {Component, Emit, Prop, Watch} from 'vue-property-decorator'
import DownloadSettings from './DownloadSettings.vue'
import SpeedSettings from './SpeedSettings.vue'
import {mapGetters} from 'vuex'
import {Preferences} from '@/types'
import WebUISettings from './WebUISettings.vue'
import RssSettings from './RssSettings.vue'
import {Config} from '@/store/config'
import { timeout } from '@/utils'

@Component({
  components: {
    DownloadSettings,
    SpeedSettings,
    WebUISettings,
    RssSettings,
  },
  computed: {
    ...mapGetters({
      config: 'config',
      preferences: 'allPreferences',
    }),
  },
  methods: {},
})
export default class SettingsDialog extends Vue {
  @Prop(Boolean)
  readonly value!: boolean
  preferences!: Preferences
  config!: Config

  preferenceUpdated = false
  tabList = ['downloads', 'speed', 'rss', 'webui']
  tab = 'download'

  @Watch('preferences')
  @Watch('config')
  async onPreferenceUpdate() {
    this.preferenceUpdated = true
    await timeout(3000)
    this.preferenceUpdated = false
  }

  @Emit('input')
  closeDialog() {
    return false
  }
}
